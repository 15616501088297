<template>
  <div>
    <div class="reg-step-1-right-title">
      <span class="reg-step-number">{{ $lang.app.application_step.replace(':step', 4).replace(':total', 5) }}</span>
      <h3 class="reg-step-title">{{ $lang.app.preschool_selection }}</h3>
    </div>
    <div class="reg-step-1-form-container">
      <h4 class="hint-title">{{ $lang.app.applicant_form_hint }}</h4>
      <div class="reg-step-form">
        <Form @submit="submit">
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.region_city }}</label>
            <div class="reg-step-1-select-container">
              <i class="bi-chevron-down"></i>
              <Field as="select" name="regionId"
                     v-model="tempForm.regionId">
                <option v-for="region in helper.regions" :key="region.id"
                        :value="region.id">{{ region.label }}</option>
              </Field>
            </div>
          </div>
          <div v-if="helper.districts.length" class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.raion }}</label>
            <div class="reg-step-1-select-container">
              <i class="bi-chevron-down"></i>
              <Field as="select" name="districtId"
                     v-model="tempForm.districtId">
                <option v-for="district in helper.districts" :key="district.id"
                        :value="district.id">{{ district.label }}</option>
              </Field>
            </div>
          </div>
<!--          <div v-if="helper.aymaks.length" class="reg-step-form-item">-->
<!--            <label class="recovery-label">{{ $lang.app.aimak }}</label>-->
<!--            <div class="reg-step-1-select-container">-->
<!--              <i class="bi-chevron-down"></i>-->
<!--              <Field as="select" name="aymakId"-->
<!--                     v-model="tempForm.aymakId">-->
<!--                <option v-for="aymak in helper.aymaks" :key="aymak.id"-->
<!--                        :value="aymak.id">{{ aymak.label }}</option>-->
<!--              </Field>-->
<!--            </div>-->
<!--          </div>-->
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.organization_selection }} <i>*</i></label>
            <div class="reg-step-1-select-container select-control-wrapper">
              <i class="bi-chevron-down"></i>
              <Multiselect
                  name="organizationId"
                  v-model="organizationForm.organizationId"
                  :options="helper.organizations"
                  :placeholder="$lang.app.select_from"
                  valueProp="id"
                  :searchable="true"
                  :noOptionsText="$lang.app.list_empty"
                  :noResultsText="$lang.app.not_found">
                <template v-slot:option="{ option }">
                  <div>
                    <div class="multiselect-custom-label">{{ option.label }}</div>
                    <div class="multiselect-custom-sublabel">{{ option.address }}</div>
                  </div>
                </template>
              </Multiselect>
            </div>
          </div>
          <div v-if="organizationForm.organizationId" class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.language_label }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <i class="bi-chevron-down"></i>
              <Field as="select" name="languageId"
                     v-model="organizationForm.languageId">
                <option value="" disabled>{{ $lang.app.select_from }}</option>
                <option v-for="language in helper.languages" :key="language.id"
                        :value="language.id">{{ language.title }}</option>
              </Field>
            </div>
          </div>
          <div v-if="organizationForm.organizationId && this.preschoolHasShortTerm()" class="reg-step-form-item">
            <div class="checkbox-container">
              <Field type="checkbox" name="shortTermGroup"
                     id="shortTermGroup"
                     @input="organizationForm.shortTermGroup = $event.target.checked"
                     :value="organizationForm.shortTermGroup"/>
              <label for="shortTermGroup">
                <span>{{ $lang.app.short_term_group }}</span>
              </label>
            </div>
          </div>

          <div v-if="info.length" class="info-block">
            <div v-for="(infoItem, index) in info" :key="index">
              <div v-if="infoItem.success" class="info-message">
                <i class="bi-info-circle"></i>
                {{ infoItem.message }}
              </div>
              <div v-else class="exclamation-message">
                <i class="bi-exclamation-circle"></i>
                {{ infoItem.message }}
              </div>
            </div>
          </div>

          <div class="reg-step-btn">
            <button @click.prevent="$emit('go-back')">{{ $lang.app.back }}</button>
            <button class="button-green" type="submit" :disabled="!isReadyToSubmit">{{ $lang.app.forward }}</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>
<script>
import { maska } from 'maska'
import { Field, Form } from 'vee-validate';

export default {
  name: 'OrganizationFormPreschool',
  components: {
    Field,
    Form,
  },
  directives: { maska },
  props: {
    applicantForm: Object,
    childrenForms: Array,
  },
  data() {
    return {
      tempForm: {
        regionId: null,
        districtId: null,
        aymakId: null,
      },
      helper: {
        regions: [],
        districts: [],
        aymaks: [],
        organizations: [],
        languages: [],
      },
      organizationForm: {
        languageId: null,
        organizationId: null,
        shortTermGroup: false,
      },
      info: []
    }
  },
  computed: {
    isReadyToSubmit() {
      return this.info.length && !this.info.find(item => {
        return !item.isReadyToSubmit
      })
    }
  },
  created() {
    this.fetchChild(4948);
  },
  watch: {
    'helper.organizations'() {
      this.organizationForm.organizationId = null
      this.helper.languages = [];
    },
    'tempForm.regionId'(value) {
      this.tempForm.districtId = null;
      this.tempForm.aymakId = null;
      this.helper.districts = [];
      this.helper.aymaks = [];
      if(value) {
        this.fetchChild(value)
        this.setOrganizations()
      }
    },
    'tempForm.districtId'(value) {
      this.tempForm.aymakId = null;
      this.helper.aymaks = [];
      if(value) {
        this.fetchChild(value)
        this.setOrganizations()
      }
    },
    'tempForm.aymakId'(value) {
      if(value) {
        this.setOrganizations()
      }
    },
    'organizationForm.organizationId'(value) {
      if(value) {
        this.organizationForm.languageId = null
        this.organizationForm.shortTermGroup = false
        this.helper.languages = []
        this.info = []
        this.getOrganizationLanguages(value)
      }
    },
    'organizationForm.languageId'() {
      this.info = []
      this.getQuota()
      this.organizationForm.shortTermGroup = false
    },
    'organizationForm.shortTermGroup'() {
      this.info = []
      this.getQuota()
    },
  },
  methods: {
    preschoolHasShortTerm() {
      for(const language of this.helper.languages){
        if(language.id === this.organizationForm.languageId
            && language.hasShortTerm === 'true'){
          return true;
        }
      }
      return false;
    },
    fetchChild(id) {
      return this.$axios.get(`/address-service/children?parent=${id}`)
          .then(({data}) => {
            if (data.length > 0) {
              switch (data[0].type) {
                case 5:
                case 2:
                  this.helper.regions = this.getParsed(data)
                  break;
                case 3:
                case 6:
                  this.helper.districts = this.getParsed(data);
                  break;
                case 4:
                case 7:
                case 101:
                  this.helper.aymaks = this.getParsed(data)
                  break;
              }
            }
          })
    },
    getParsed(data) {
      return data.map(item => {
        return {
          id: item.id,
          label: this.$lang.$translate({
            ru: item.nameRu,
            kg: item.nameKg + ' ' + item.typeKgShort
          })
        }
      })
    },
    setOrganizations() {
      this.$store.commit('startLoading')
      this.$axios.get('/organization/filter', {
        params: {
          regionId: this.tempForm.regionId,
          districtId: this.tempForm.districtId,
          aimakId: this.tempForm.aymakId,
          type: 'PRESCHOOL',
        }
      }).then(({data}) => {
        this.helper.organizations = this.getParsedOrganizations(data)
      }).finally(() => {
        this.$store.commit('stopLoading');
      })
    },
    getParsedOrganizations(data) {
      return data.map(item => {
        return {
          id: item.id,
          label: this.$lang.$translate({ru: item.nameRU, kg: item.nameKG }),
          address: this.$lang.$translate({ru: item.fullAddressRU, kg: item.fullAddressKG })
        }
      })
    },
    getOrganizationLanguages(organizationId) {
      this.$axios.get(`/lang/organization-group/${organizationId}/organization`)
          .then(({data}) => {
            this.helper.languages = data
          }).finally(() => {
        this.$store.commit('stopLoading');
      })
    },
    async getQuotaForChild(childrenForm) {
      if (this.organizationForm.languageId == null){
        return;
      }

      await this.$recaptchaLoaded()
      let recaptchaToken = await this.$recaptcha('login')

      this.$store.commit('startLoading')
      this.$axios.post('/organization/quotas', {
        applicantPrivilegeId: this.applicantForm.privilegeTypeId,
        childPrivilegeId: childrenForm.privilegeTypeId,
        dateOfBirth: childrenForm.dateOfBirth,
        organizationId: this.organizationForm.organizationId,
        languageId: this.organizationForm.languageId,
        shortTermGroup: this.organizationForm.shortTermGroup,
        type: 'PRESCHOOL',
        recaptchaToken: recaptchaToken,
      }).then(({data}) => {
        if(data.message) {
            childrenForm.organizationGroupId = data.organizationGroupId
            this.info.push({
              ...data,
              isReadyToSubmit: true
            })
        }
      }).catch(error => {
        if(error.response.data.status === 404) {
          this.info.push({
            success: false,
            message: error.response.data.message,
            isReadyToSubmit: false
          })
        }
        this.$store.commit('stopLoading');
      }).finally(() => {
        this.$store.commit('stopLoading');
      })

    },
    getQuota() {
      this.childrenForms.forEach(childrenForm => {
        this.getQuotaForChild(childrenForm)
      })
    },
    submit() {
      this.$emit('organizations', this.helper.organizations)
      this.$emit('languages', this.helper.languages)
      this.$emit('validated', this.organizationForm)
    }
  }
}
</script>
<style>
@import '../../assets/styles/multiselect.css';
.select-control-wrapper .multiselect {
  font-size: 20px !important;
}
.info-block {
  margin: 10px 0;
}
.info-block i {
  font-size: 30px;
  margin-right: 10px;
}
.info-block .info-message {
  color: dodgerblue;
  display: flex;
  align-items: center;
}
.info-block .exclamation-message {
  color: indianred;
  display: flex;
  align-items: center;
}
.my-apps-detail-tabs-links {
  display: flex;
  margin-bottom: 20px;
}
.my-apps-detail-tabs-links a:hover {
  text-decoration: underline;
}
.my-apps-detail-tabs-links .my-apps-detail-tabs-links-active {
  background: #fff;
  color: #1f1f1f;
  z-index: 15;
}
.my-apps-detail-tabs-links a {
  position: relative;
  z-index: 5;
  margin-bottom: -1px;
  display: inline-block;
  padding: 20px 0;
  width: 50%;
  text-align: center;
  background: #ebf8ff;
  border-top: 1px solid #d0d0d0;
  border-right: 1px solid #d0d0d0;
  border-left: 1px solid #d0d0d0;
  color: #4c9ac7;
  text-decoration: none;
  font-weight: bold;
}
.organization-tab {
  width: 50% !important;
}
@media screen and (max-width: 1200px) {
  .my-apps-detail-tabs-links a {
    width: 33.3333%;
  }
}
</style>