<template>
  <div class="registration-step-1-block">
    <div class="page-title"><h2>{{ $lang.app.application_to_preschool }}</h2></div>
    <div class="reg-step-1-container" id="tabTop">
      <div class="reg-step-1-left">
        <div class="reg-step-1-left-links">
          <a v-for="(tab, index) in tabs" :key="index"
             href @click.prevent="activateTab(index)"
             :class="{'reg-step-1-left-active' : index === activeTab, 'reg-step-1-left-complete' : index < activeTab }">
            <span :class="{'opacity-0': !index}">{{ index }}</span>
            <p>{{ tab }}</p>
          </a>
        </div>
      </div>
      <div class="reg-step-1-right">
        <Instructions v-show="activeTab === 0"
                      type="PRESCHOOL"
                      @validated="completeInstructions"/>
        <ApplicantForm v-show="activeTab === 1"
                       type="PRESCHOOL"
                       :relation-types="helper.relationTypes"
                       :country-codes="helper.countryCodes"
                       :citizenships="helper.citizenships"
                       :passport-ranges="helper.passportRanges"
                       :privilege-types="helper.applicantPrivilegeTypes"
                       @validated="completeApplicantForm"
                       @go-back="activeTab--"/>
        <ChildrenForms v-show="activeTab === 2"
                       :applicant-form="applicantForm"
                       :genders="helper.genders"
                       :birth-certificate-ranges="helper.birthCertificateRanges"
                       :privilege-types="helper.childPrivilegeTypes"
                       :citizenships="helper.childCitizenships"
                       type="PRESCHOOL"
                       @validated="completeChildrenForms"
                       @go-back="activeTab--"/>
        <OrganizationFormPreschool v-show="activeTab === 3"
                          :applicant-form="applicantForm"
                          :children-forms="childrenForms"
                          @organizations="setOrganizations"
                          @languages="setLanguages"
                          @validated="completeOrganizationForm"
                          @go-back="activeTab--"/>
        <Completion v-show="activeTab === 4"
                    type="PRESCHOOL"
                    :applicant-form="applicantForm"
                    :children-forms="childrenForms"
                    :organization-form="organizationForm"
                    :relation-types="helper.relationTypes"
                    :citizenships="helper.citizenships"
                    :child-citizenships="helper.childCitizenships"
                    :applicant-privilege-types="helper.applicantPrivilegeTypes"
                    :genders="helper.genders"
                    :child-privilege-types="helper.childPrivilegeTypes"
                    :languages="helper.languages"
                    :organizations="helper.organizations"
                    @go="activeTab = $event"/>
      </div>
    </div>
  </div>
</template>
<script>
import Instructions from '../components/application/Instructions.vue'
import ApplicantForm from '../components/application/ApplicantForm.vue'
import ChildrenForms from '../components/application/ChildrenForms.vue'
import OrganizationFormPreschool from '../components/application/OrganizationFormPreschool.vue'
import Completion from '../components/application/Completion.vue'
export default {
  name: 'ApplicationPreschool',
  components: {
    Instructions,
    ApplicantForm,
    ChildrenForms,
    OrganizationFormPreschool,
    Completion
  },
  data() {
    return {
      activeTab: 0,
      tabs: [
        this.$lang.app.instruction_title,
        this.$lang.app.parent_info,
        this.$lang.app.child_info,
        this.$lang.app.preschool_selection,
        this.$lang.app.application_review,
      ],
      helper: {
        relationTypes: [],
        citizenships: [
          {
            label: this.$lang.app.citizen_kg,
            value: 'KG'
          },
          {
            label: this.$lang.app.citizen_other,
            value: 'FOREIGN'
          }
        ],
        childCitizenships: [
          {
            label: this.$lang.app.child_citizen_kg,
            value: 'KG'
          },
          {
            label: this.$lang.app.child_citizen_foreign,
            value: 'FOREIGN'
          }
        ],
        passportRanges: ['AN', 'ID'],
        countryCodes: [],
        applicantPrivilegeTypes: [],
        genders: [
          {
            label: this.$lang.app.male,
            value: 'MALE'
          },
          {
            label: this.$lang.app.female,
            value: 'FEMALE'
          }
        ],
        birthCertificateRanges: ['KP-X', 'KGZ01'],
        childPrivilegeTypes: [],
        languages: [],
        organizations: [],
      },
      applicantForm: {},
      childrenForms: [],
      organizationForm: {},
    }
  },
  created() {
    this.setRelationTypes()
    this.setCountryCodes()
    this.setApplicantPrivilegeTypes()
    this.setChildPrivilegeTypes()
  },
  methods: {
    activateTab(index) {
      if(index === 0) {
        this.activeTab = index
      }
    },
    setRelationTypes() {
      this.$axios.get(`/relation-type/get?lang=${this.$lang.$current}`).then(({data}) => {
        this.helper.relationTypes = data
      })
    },
    setCountryCodes() {
      this.$axios.get(`/country`).then(({data}) => {
        this.helper.countryCodes = data
      })
    },
    setApplicantPrivilegeTypes() {
      this.$axios.get(`/privilege-type/list/by`, {
        params: {
          type: 'PRESCHOOL',
          code: 'applicant_privilege_type',
          lang: this.$lang.$current
        }
      }).then(({data}) => {
        this.helper.applicantPrivilegeTypes = data
      })
    },
    setChildPrivilegeTypes() {
      this.$axios.get(`/privilege-type/list/by`, {
        params: {
          type: 'PRESCHOOL',
          code: 'child_privilege_type',
          lang: this.$lang.$current
        }
      }).then(({data}) => {
        this.helper.childPrivilegeTypes = data
      })
    },
    completeInstructions() {
      this.activeTab++
      this.$jump('#tabTop', {
        duration: 200,
        easing: t => t,
      })
    },
    completeApplicantForm(form) {
      this.applicantForm = form
      this.activeTab++
      this.$jump('#tabTop', {
        duration: 200,
        easing: t => t,
      })
    },
    completeChildrenForms(forms) {
      this.childrenForms = forms
      this.activeTab++
      this.$jump('#tabTop', {
        duration: 200,
        easing: t => t,
      })
    },
    completeOrganizationForm(form) {
      this.organizationForm = form
      this.activeTab++
      this.$jump('#tabTop', {
        duration: 200,
        easing: t => t,
      })
    },
    setOrganizations(organizations) {
      this.helper.organizations = organizations
    },
    setLanguages(languages) {
      this.helper.languages = languages
    },
  }
}
</script>
<style>
@import '../assets/styles/form.css';
@import '../assets/styles/title.css';
@import '../assets/styles/multiselect.css';

.modal-content iframe {
  height: calc(100vh - 100px);
  margin-bottom: 30px;
}
</style>